body {
  margin: 0;
  /* font-family: 'Anton', sans-serif; */
  font-family: 'Plus Jakarta Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F5F5;
  
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  border-radius: 16px;
}

::-webkit-scrollbar-track {
  margin: -15px 0 0 0;
}

::-webkit-scrollbar-thumb {
  background-color: #000;
}